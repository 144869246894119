import React, { Component } from "react";
import _data from "../../data";

class Business extends Component {
  constructor(props) {
    super(props);
    this.state = {
      testimonial: {}
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
    this.setState({
      testimonial: _data.testimonial
    });
  }

  render() {
    return (
      <React.Fragment>
        <section className="ptb-100 gray-light-bg">
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-lg-6 col-md-6">
                <div className="about-content">
                  <img src="img/fast.jpg" alt="" className="img-fluid" />
                </div>
              </div>
              <div className="col-lg-5 col-md-6">
                <div className="about-content">
                  <h2>We help drive your business forward</h2>
                  <p className="text-justify">
                    Efficiently simplify innovative supply chains vis-a-vis
                    extensive supply chains. Globally whiteboard quality
                    initiatives vis-a-vis open-source opportunities.
                    Compellingly procrastinate viral leadership for premier
                    markets. Energistically recaptiualize strategic systems via
                    resource maximizing solutions. Competently customize
                    intuitive benefits for user friendly platforms.{" "}
                  </p>
                  <p className="text-justify">
                    Distinctively leverage other's market-driven architectures
                    without team driven ideas. Progressively foster user-centric
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Business;
