import React from "react";
import _data from "../../data";

class Promo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      promo: {},
      trustedCompany: []
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */

    this.setState({
      promo: _data.promo2
    });
  }

  render() {
    return (
      <React.Fragment>
        <section className="promo-section pb-100">
          <div className="container">
            <div className="row equal">
              {(this.state.promo.items || []).map((item, index) => {
                return (
                  <div className="col-md-4 col-lg-4" key={index}>
                    <div className="single-promo single-promo-hover text-center gray-light-bg h-100">
                      <div><img src={item.image8} alt="service" className="img-fluid img-full-box" /></div>
                      <div className="p-4">
                        <h5>{item.title}</h5>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Promo;
