import React from 'react'

const About = () => {
    return (
        <div>
            {/* start header section */}
            <section className="call-to-action ptb-100 background-img"
             style={{background: "url('img/hero-bg-2.jpg')", backgroundRepeat:"no-repeat", backgroundPosition: "center", backgroundSize: "center / cover", backgroundAttachment: "fixed"}}>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-11 mb-3">
                            <div className="call-to-action-content text-center text-white">
                                <h2 className="text-white">Discover ArbataTech. It's easier than you think.</h2>
                                <p>Progressively deliver market-driven quality vectors rather than goal-oriented niche markets.</p>
                            </div>
                        </div>
                        <div className="col-11">
                            <div className="call-to-action-btn text-center">
                                <a href="/#" target="_blank" className="btn solid-btn"> Buy ArbataTech <span>$14</span> Only</a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* end header section */}
        </div>
    )
}


export default About;