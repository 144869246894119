import React, {Component} from 'react'

import Header from '../components/Header/header';
import HeroSection from '../components/HeroSection/heroSectionAboutUs';
import PromoSection from '../components/PromoSection';
import ServiceSection1 from '../components/Service/ServicePage1';
import ServiceSection2 from '../components/Service/Service2';
import Testimonial from '../components/Testimonial';
import ActionToCall from '../components/ActionToCall';
import Footer from '../components/footer/footer';


class Services extends Component{
    render(){
        return(
            <div>
                <Header />
                <div className="main">
                    <HeroSection title="Our services" />
                    <PromoSection />
                    <ServiceSection1 />
                    <ServiceSection2 />
                    <Testimonial bgColor="gray" />
                    <ActionToCall />
                </div>
                <Footer />
            </div>
        )
    }
}

export default Services;