import React from "react";
import _data from "../../data";

class Service extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      service: {}
    };
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
    this.setState({
      service: _data.service4
    });
  }

  render() {
    return (
      <React.Fragment>
        <section id="services" className="our-services-section ptb-100">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-7">
                <p className="color-secondary">
                  <strong>Our Services</strong>
                </p>
                <h2>{this.state.service.title}</h2>
                <p>{this.state.service.description}</p>
                <div className="row mt-5">
                  {(this.state.service.items || []).map((item, index) => {
                    return (
                      <div className="col single-feature mb-4" key={index}>
                        <div className="d-flex align-items-center mb-2">
                          <span className={item.icon}></span>
                          <h5 className="mb-0">{item.title}</h5>
                        </div>
                        <p>{item.description}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="col-md-5">
                <div className="service-content-left">
                  <img
                    src="img/app-developemnt.svg"
                    alt="our services"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Service;
