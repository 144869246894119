import React, {Component} from 'react'

import Header from '../components/Header/header';
import HeroSection from '../components/HeroSection/heroSection7';
import PromoSection from '../components/PromoSection/Promo7';
import TrustedCompany from '../components/TrustedCompany/TrustedCompany7';
import AboutSection from '../components/AboutUs/AboutUs6';
import Video from '../components/Video';
import ServiceSection from '../components/Service/Service7';
import Pricing from '../components/Pricing';
import Testimonial from '../components/Testimonial';
import Contact from '../components/Contact';
import ActionToCall from '../components/ActionToCall';
import Footer from '../components/footer/footer';


class Theme7 extends Component{
    render(){
        return(
            <div>
                <Header />
                <div className="main">
                    <HeroSection />
                    <PromoSection />
                    <TrustedCompany />
                    <AboutSection />
                    <Video />
                    <ServiceSection />
                    <Pricing />
                    <Testimonial />
                    <Contact />
                    <ActionToCall />
                </div>
                <Footer />
            </div>
        )
    }
}

export default Theme7